import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "ลูบหัวสัตว์"
    }}>{`ลูบหัวสัตว์`}</h3>
    <p>{`ภายในฟาร์มของผู้เล่นจะมีแมวและควายอยู่ ผู้เล่นสามารถเดินไปลูบหัวสัตว์พวกนี้ได้ ในบางวันสัตว์พวกนี้อาจจะเอาของบางอย่างมาให้คุณได้ด้วยนะ ( โอกาสได้รับไอเทม จะสามารถลุ้นได้จากการลูบหัวสัตว์ครั้งแรกของวันเท่านั้น )`}</p>
    <p><img alt="mat" src={require("./public/images/pet.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      